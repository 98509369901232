import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyle, mapOptions } from './constants';


export default class Maps {
  constructor() {
    this.mapContainer = document.getElementById('map_canvas');
    if (this.mapContainer) {
      loadGoogleMapsApi({
        key: 'AIzaSyCYp1I2K16FF8CG1DyH_srksrf8cc1i3ew',
      }).then((google) => {
        this.google = google;
        this.init(google);
      });
    }
  }

  init = (google) => {
    this.mapCenter = new google.LatLng(25.286774, 51.530972);
    this.mapIconUrl = `${CCM_APPLICATION_URL}/application/themes/cicon/dist/images/marker.svg`;
    this.map = new google.Map(this.mapContainer, {
      ...mapOptions(google),
      ...{
        center: this.mapCenter,
        styles: mapStyle,
      },
    });

    this.infowindow = new google.InfoWindow({})

    const locations = [ 
      {name: 'Dubai', id: 'id_dubai', lat: 25.216494, lng: 55.277703,no:2 },
      {name: 'Abu Dhabi', id: 'id_abu_dhabi', lat: 24.478954, lng: 54.382153,no:2 },
      {name: 'Qatar', id: 'id_qatar', lat: 25.003672, lng: 51.572528,no:1 },
      {name: 'Umm Al Quwain',id: 'id_umm_al_quwain',lat: 25.540969,lng: 55.685406,no:1},
      {name: 'Kochi',id: 'id_kochi',lat: 9.990238,lng: 76.294876,no:1},
      
    ];

    locations.map(item => {
      this[`${item.id}-marker`] = new google.Marker({
        position: new google.LatLng(item.lat, item.lng),
        map: this.map,
        icon: this.mapIconUrl,
        title: item.name
      });
      this.google.event.addListener(this[`${item.id}-marker`], 'click', (marker, count) => {
        let more_content = '';
        for(let i = item.no; i > 1; i-=1){
          more_content += $(`#${item.id}${i}`).html()
        }
        const content = $('#'+item.id).html() + more_content;
        
        $('.cicon_country_list .swiper-slide').removeClass('active')
        $('.cicon_country_list .swiper-slide[title="'+item.name+'"]').addClass('active')
        this.infowindow.setContent(`<div id="id_`+item.id+`" class="map_location_block">`+content+ `</div>`)
        this.infowindow.open(this.map, this[`${item.id}-marker`]);
      });
    })

    this.bindEvents();
  };

  bindEvents = () => {
    this.google.event.addListener(this.marker, 'click', this.toggleBounce);
  };

  toggleBounce = (markerClicked) => {
    if (markerClicked.getAnimation() != null) {
      markerClicked.setAnimation(null);
    } else {
      markerClicked.setAnimation(this.google.Animation.BOUNCE);
    }
  };
}
