import Swiper from "swiper";
import AOS from "aos";
import fancybox from "@fancyapps/fancybox";
import print from "print-js";
import chosen from "chosen-js";
import selectpicker from "bootstrap-select";
import mCustomScrollbar from "malihu-custom-scrollbar-plugin";

if(!$('body').hasClass('edit-mode')){
 require('bootstrap');
}

//import bootstrap from "bootstrap";
/*import SplitText from "split-text";*/
/*import charming from "charming";*/
import {TweenMax, Power2, TimelineLite} from "gsap/TweenMax";
import Header from './components/Header';
import Maps from './components/Maps';

import './external/bootstrap/bootstrap.min.css';
import './external/swiper/swiper.min.css';
import './external/aos/aos.min.css';
import './external/fancybox/fancybox.min.css';
import './external/chosen/chosen.min.css';
import './external/selectpicker/selectpicker.min.css';
import './external/mCustomScrollbar/mCustomScrollbar.min.css';


export default new class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    // dom ready shorthand
    $(() => {
      this.domReady();
    });
  }


  domReady = () => {
    this.initComponents();
    this.handleUserAgent();
    this.windowResize();
    this.bindEvents();
    this.handleSplashScreen();
    this.initExternal();
    // console.log('Initializing Dom events');
  };

  initComponents = () => {
    new Header({
      header: this.header,
      htmlBody: this.htmlBody,
    });

    if (this.mapContainer.length) {
      new Maps({
        mapContainer: this.mapContainer,
      });
    }
  };

  initExternal = () => {



    setTimeout(function(){

      // mCustomScrollbar  start
      if( $(window).width() > 767){
        $('.modal_body_part').mCustomScrollbar({ 
                theme:"dark-3",
                axis:"y",
                mouseWheel:true,      
        });
      }
      // mCustomScrollbar  End 

      // BOOTSTRAP DROPDOWN Start
      $('#pcategory_select').selectpicker({
         noneSelectedText:'Projects Category',
         dropupAuto:false,
      });

      //
      $('#segments_select').selectpicker({
         noneSelectedText:'By Segment',
         dropupAuto:false,
      });

      $(document).click(function(){
          $(".bootstrap-select").removeClass("open");
      });


      $('body').on('click', '.btn.dropdown-toggle', function() {
        if (!$('html').is('.ieEdge, .ie10')) {
          if($(window).width() >860 && $('body').hasClass('logged-in')){
              $(this).parent().toggleClass("open");
          } 
        }
      });

      if($('.all_project-page').length){
        $('div.dropdown-menu').append('<div class="submit_filter">\
                                        <span class="clear_all clear_select_picker pull-left">Clear</span>\
                                        <input type="submit" class="btn pull-right" value="Apply">');
        
      }

      $('body').on('click', '.clear_select_picker', function() {
        $(this).parent().parent().parent().find('select').selectpicker('deselectAll');
      });
      $('body').on('click', 'div.dropdown-menu', function(event) {
        event.stopPropagation();
      });


      $('#pcategory_select').parent('div').find("input[value='Apply']").on('click', function(){
        var selectedCategories = $('select[name="project_category[]"]').map(function(){
          if ($(this).val())
            return $(this).val();
        }).get();
        var current_href = window.location.href.split('?');
        if (selectedCategories && selectedCategories.length > 0) {
            var uri = "?project_category=";
            var categories="";

            for (var i=0; i < selectedCategories.length; i++) {
                if (i> 0)
                    categories += ','
                categories+= selectedCategories[i];
            }

            if(current_href.length === 2){
              var params = current_href[1].split('&');
              if (params.length > 1){
                if (params[1].includes('project_segment'))
                  window.location.href = current_href[0] + uri + categories + '&' +params[1];
              }
                if (params[0].includes('project_segment'))
                    window.location.href = current_href[0] + uri + categories + '&' +params[0];
                else{
                    window.location.href = current_href[0] + uri + categories
                }
            } else{
              window.location.href = current_href[0] + uri + categories;
            }

        } else{
          if(current_href.length === 2){
            var params = current_href[1].split('&');
            if (params.length > 1){
              if (params[1].includes('project_segment'))
                window.location.href = current_href[0] + '?' +params[1];
            } else {
              if (params[0].includes('project_segment'))
                window.location.href = current_href[0] + '?' +params[0];
              else{
                window.location.href = current_href[0];
              }
            }
          } else{
            window.location.href = current_href[0];
          }
        }
      });


      $('#segments_select').parent().find("input[value='Apply']").on('click', function () {
        var selectedSegments = $('select[name="project_segment[]"]').map(function(){
          if ($(this).val())
            return $(this).val();
        }).get();
        var current_href = [window.location.href];

        if (selectedSegments && selectedSegments.length > 0) {
          var uri = "";
          var segments = "";
          for (var i = 0; i < selectedSegments.length; i++) {
            if (i === 0) {
              current_href = window.location.href.split('?');
              if (!window.location.href.includes('project_category') ) {
                uri += "?project_segment=";
              } else {
                var params = current_href[1].split('&');
                uri += '?'+params[0];
                uri += "&project_segment=";
              }
              segments += selectedSegments[i]
            } else {
              segments += "," + selectedSegments[i]
            }
          }

          window.location.href = current_href[0] + uri + segments;
        } else{
          current_href = window.location.href.split('?');
          if(current_href.length === 2){
            var params = current_href[1].split('&');
            if (params.length === 1){
              if (params[0].includes('project_category'))
                window.location.href = current_href[0] + '?' +params[0];
              else
                window.location.href = current_href[0];
            } else {
              window.location.href = current_href[0] + '?' +params[0];
            }
          } else{
            window.location.href = current_href[0];
          }
        }
      });

      // BOOTSTRAP DROPDOWN  End


      // News Start 
      $('.news_category_filter').on('click', function () {
        var current_href = window.location.href.split('?');
        if ($(this).data('value') !== 0){
          window.location.href = current_href[0]+ "?news_category="+ encodeURI($(this).data('value'));
        } else{
          window.location.href = current_href[0]
        }
      });

      // News End

      // AOS Start
        AOS.init({
            disable : 'tablet',
        });
      // AOS Ends


      // fancy box

        $('[data-fancybox="gallery"]').fancybox({
          // Options will go here
          thumbs          : false,
          hash            : false,
          loop            : true,
          keyboard        : true,
          toolbar         : true,
          animationEffect : false,
          arrows          : false,
          clickContent    : false,
          afterLoad : function( instance, current ) {
                            if ( instance.group.length > 1 && current.$content ) {
                              current.$content.append('\
                                    <a data-fancybox-next class="button-next fancybox-button fancybox-button--arrow_right icon-arrow-small-right" href="javascript:;"></a>\
                                    <a data-fancybox-prev class="button-previous fancybox-button fancybox-button--arrow_left icon-arrow-small-left" href="javascript:;"></a>\
                                ');
                              setTimeout(function() {
                                $('.button-previous, .button-next').show()
                              },500);
                              
                            }
          }
        });

      // fancy box Ends

      // swiper slider Start

      var interleaveOffset = 0.5;
      var autoplay = 4000;

      var swiperOptions = {
        loop: true,
        speed: 1000,
        autoplay: {
                  delay: autoplay,
                  },
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        //paginationClickable: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //onProgress: move,
        on: {
          progress: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + 0 + "px, 0, 0)";

            } 

            //move(i,swiper.slides.length)
              
          },
          touchStart: function() {

            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function(speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
              
              
            }
          },


          slideChangeTransitionStart:function(){
            TweenMax.staggerTo(".page_banner .swiper-slide-active h1", 1.8 , {opacity:1,x:0}, .5);
            //TweenMax.staggerTo(".page_banner .swiper-slide-active .slide-inner", 1.8 , {scale:1.2 , ease:Elastic.easeOut});
          },
          slideChangeTransitionEnd:function(){
            /*TweenMax.staggerTo(".page_banner .swiper-slide-prev .slide-inner", 1.8 , {scale:1});
            TweenMax.staggerTo(".page_banner .swiper-slide-next .slide-inner", 1.8 , {scale:1});*/
            TweenMax.staggerTo(".page_banner .swiper-slide-prev h1", 1.8 , {opacity:0,x:200}, .5);
            TweenMax.staggerTo(".page_banner .swiper-slide-next h1", 1.8 , {opacity:0,x:200}, .5);
          },
          
        }


      };
      TweenMax.staggerTo(".page_banner_single .banner_content h1", 1 , {opacity:1,x:0}, .5);
      TweenMax.staggerTo(".breadcrumb", 3.5 , {opacity:1}, .5);
      TweenMax.staggerTo(".page_banner_single .img_div", 1 , {scale:1}, .5);

      
      var swiperOptions2 = {
        loop: true,
        speed: 1000,
        autoplay: {
                  delay: autoplay,
                  disableOnInteraction:false,
                  },
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        //paginationClickable: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //onProgress: move,
        on: {
          progress: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + 0 + "px, 0, 0)";
            } 

            //move(i,swiper.slides.length)
              
          },
          touchStart: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function(speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
              
            }
          },
          slideChangeTransitionStart:function(){
            setTimeout(function(){
               $('.img_gallery #progress').addClass('active');
            }, 300); //time can be any number  
          },
          slideChange:function(){
            $('.img_gallery #progress').removeClass('active');
            
          },
        }
      };
      
      setTimeout(function(){
       var swiper = new Swiper(".page_banner .swiper-container", swiperOptions);
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper(".img_gallery .swiper-container", swiperOptions2);
        $('.img_gallery #progress').addClass('active');
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.home_pjt_cate_listing .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.capability_pjt_cate_listing .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.all_pjt_sec .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.certifications_list .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.related_pjt_cate_listing .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
         $('.related_pjt_list #progress1').addClass('active');
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.segment_projects .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.related_pjt_list .swiper-container', {

        
          loop: true,
          speed: 1000,
          autoplay: {
                  delay: autoplay,
                  disableOnInteraction:false,
                  },
          grabCursor: true,
          watchSlidesProgress: true,
          mousewheelControl: true,
          keyboardControl: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },

          on: {
          progress: function() {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + 0 + "px, 0, 0)";
            } 

            //move(i,swiper.slides.length)
              
          },
          touchStart: function() {

            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function(speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },


          slideChangeTransitionStart:function(){
            //alert(2)
            setTimeout(function(){
               $('.related_pjt_list #progress1').addClass('active');
            }, 300); //time can be any number  
            
            
          },
          slideChange:function(){
            $('.related_pjt_list #progress1').removeClass('active');
            
          },
        }

        });
        $('.related_pjt_list #progress1').addClass('active');
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.cicon_country_list .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
        });
      }, 100); //time can be any number

      setTimeout(function(){
        var swiper = new Swiper('.news_page_content .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 30,
          freeMode: true,
        });
      }, 100); //time can be any number
    
    // swiper slider End

    // fancy box modal open on page load by condition - Start
    if($('body').hasClass('who_we_are-page')){
      if (window.location.href.indexOf("ceo_msg") > -1) {
        $("#modalCEOMessage").fancybox().trigger('click');
      }
    }

    $('.footer_1 ul li:first-child').click(function () {
      if($('body').hasClass('who_we_are-page')){
        if (window.location.href.indexOf("ceo_msg") > -1) {
          $("#modalCEOMessage").fancybox().trigger('click');
        }
      }
    });

    if($('body').hasClass('certifications-page')){
      if (window.location.href.indexOf("certification_modal") > -1) {
        var modal_name = window.location.href.split('#')[1]
        //alert(modal_name)
        $("#"+modal_name).fancybox().trigger('click');
        $('body, html').animate({
          scrollTop: $('.certifications_list').position().top + 400,
        });
      }
    }

    $('.footer_certification a img').click(function () {
      if($('body').hasClass('certifications-page')){ 
        if (window.location.href.indexOf("certification_modal") > -1) {
          var modal_name = window.location.href.split('#')[1]
          //alert(modal_name)
          $("#"+modal_name).fancybox().trigger('click');
        }
      }
    });

    // fancy box modal open on page load by condition - End

    // svg img click event and close - Start
      
    if($('#gmap_loc').length){
      var svg_top = '';
      var svg_width = $('#gmap_loc').width();
      var screen_width = $(window).width()
    }
     
    /*$('#gmap_loc .map_loc').click(function () {
      var svg_top = $('#gmap_loc')[0].getBoundingClientRect().top;
      var block = $(this).attr('block_name');
      var block_name = '#id_'+$(this).attr('block_name');
      var left_pos = $('g[block_name="'+block+'"] .marker_pos')[0].getBoundingClientRect().left;
      var top_pos = Math.abs($('g[block_name="'+block+'"] .marker_pos')[0].getBoundingClientRect().top - svg_top - 20);
      if(screen_width <= 767){  
        top_pos = top_pos;

        
        if(Math.abs($('#gmap_loc')[0].getBoundingClientRect().left ) == 32){ // 64 is from margin of container
           left_pos  = left_pos -32;

        }else{
          
          left_pos = left_pos  + Math.abs($('#gmap_loc')[0].getBoundingClientRect().left );
          
        }
        $(block_name).css({
            top: top_pos+'px',
            left:left_pos +'px',
            right:'auto'
          })
      }else{
        if(screen_width <= 1024){
          if(left_pos > (.6*screen_width - 150) ){
            var right_val =svg_width -(left_pos -((screen_width - svg_width)/2)) - 10
            $(block_name).css({
              top: top_pos+'px',
              right:right_val  +'px',
              left:'auto'
            })
          }else{
            var left_val =left_pos -((screen_width - svg_width)/2) +40
            $(block_name).css({
              top: top_pos+'px',
              left:left_val +'px',
            })
          }
        }else if(left_pos < .6*screen_width ){
          var left_val =left_pos -((screen_width - svg_width)/2) +20
          $(block_name).css({
            top: top_pos+'px',
            left:left_val +'px',
          })
          
        }else{
          var right_val =svg_width -(left_pos -((screen_width - svg_width)/2)) +20
          $(block_name).css({
            top: top_pos+'px',
            right:right_val  +'px',
            left:'auto'
          })
        }
      }


      
      $('.map_location_block').removeClass('active');
      $(block_name).addClass('active');
    });*/

    $('.map_location_block .icon-close').click(function () {
      $(this).parent().removeClass('active');
    });

    // svg img click event and close - End


    // Mobile menu responsive - Start

    $('.mob_menu_icon').click(function () {
      $(this).toggleClass('open');
      $('.nav_header').toggleClass('open');
      $('html').toggleClass('stop_overflow');
      $('body').toggleClass('stop_overflow');
      $('.mob_menu_footer').toggleClass('open');
      if(!$(this).hasClass('open')){
        $('.mob_menu_footer .sec_2').hide();
        $('.mob_menu_footer .sec_1').show();
      } 
    });

    $(".nav_header .sub_menu > a").click(function(e) {
      if( $(window).width() <= 768){
        e.preventDefault()
        $(this).parent().find('.submenu_block').addClass('show');
        $('.mob_menu_footer .sec_1').hide();
        $('.mob_menu_footer .sec_2').show();
      }
    });

    $(".mob_menu_footer .sec_2 span").click(function(e) {
      $(this).parent().find('.submenu_block').removeClass('show');
      $('.mob_menu_footer .sec_1').show();
      $('.mob_menu_footer .sec_2').hide();
    });

    // Mobile menu responsive - End
  
    // pagination class addition - Start
    if($('.pagination').length){
      if($('.pagination .prev a').length){
        $('.pagination .prev a').html('<span class="icon-arrow-small-left"></span>');
      }else{
        $('.pagination .prev').html('<span class="icon-arrow-small-left"></span>');
      }
      if($('.pagination .next a').length){
        $('.pagination .next a').html('<span class="icon-arrow-small-right"></span>');
      }else{
        $('.pagination .next').html('<span class="icon-arrow-small-right"></span>');
      }
      $('.pagination').css('opacity','1');
    }
    // pagination class addition - End

      
    // google map click event - Start
    $('.cicon_country_list .swiper-slide').click(function () {
      $('.cicon_country_list .swiper-slide').removeClass('active');
      $(this).addClass('active');
      var country_name = $(this).text();
      $('div[title="'+country_name+'"]').trigger("click");
    });
    // google map click event - End

    // news show more details toggle - start
    $('.news_list .show_more_news').click(function () {
      var stat = $(this).parent().hasClass('active');
      var that = $(this)
      $(this).parent().toggleClass('active');
      if(stat){
        $(this).parent().find('.icon_list').slideUp();
        setTimeout(function(){
          that.text('More Information')
        }, 400);
      }else{
        $(this).parent().find('.icon_list').slideDown();
        setTimeout(function(){
          that.text('Show less');
        }, 400);
      }
    });
    // news show more details toggle - End

    // header submenu on hover - start

    var timeoutId1;
    $(".nav_header .sub_menu").hover(function() {
      if( $(window).width() > 1024){
        var that = $(this);
        if (!timeoutId1) {
            timeoutId1 = window.setTimeout(function() {
              timeoutId1 = null; 
              //that.addClass('nav-path-selected'); 
              var left_pos = that.position().left;
              var top_pos = that.position().top
              var block_name = that.attr('block_name');
              $('.'+block_name).addClass('show');
              if($(window).width() > 1560){
                $('.'+block_name).css('left',left_pos+'px');
              }else{
                left_pos = left_pos - 100;
                $('.'+block_name).css('left',left_pos+'px');
              }
              
           }, 100);
        }
      }
    },
    function () {
        if (timeoutId1) {
            window.clearTimeout(timeoutId1);
            timeoutId1 = null;
        }
        else {
            //$(".nav_header .sub_menu").removeClass('nav-path-selected');
            $('.submenu_block').removeClass('show');
        }
    });

    // hover on header menu effect
    var timeoutId2;

    $(".nav_header ul li").hover(function() {

        var that = $(this);
        if (!timeoutId2) {
            timeoutId2 = window.setTimeout(function() {
                timeoutId2 = null;
                that.addClass('nav_hover'); 
           }, 300);
        }
    },
    function () {
        if (timeoutId2) {
            window.clearTimeout(timeoutId2);
            timeoutId2 = null;
        }
        else {
            $(".nav_header ul li").removeClass('nav_hover');
        }
    });

    }, 100); //time can be any number
    
    // header submenu on hover - End

  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $('body, html');
    this.html = $('html');
    this.htmlBody = $('body');
    this.siteLoader = $('.site-loader');
    this.header = $('header');
    this.siteBody = $('.site-body');
    this.footer = $('footer');
    this.gotoTop = $('#gotoTop');
    this.gRecaptcha = $('.g-recaptcha');
    this.wrapper = $('.wrapper');
    this.pushDiv = this.wrapper.find('.push');
    this.mapContainer = $('#map_canvas');
    this.scrollDown = $('.scroll_down');
    this.overlay_footer = $('.overlay_div_footer');
    this.history_block_outer = $('.history_block_outer');


    this.inputs = $('input, textarea').not('[type="checkbox"], [type="radio"]');
  };

  bindEvents = () => {
    // Window Events
    this.window.resize(this.windowResize).scroll(this.windowScroll);

    // General Events
    const $container = this.wrapper;

    $container.on('click', '.disabled', () => false);

    // Specific Events
    this.gotoTop.on('click', () => {
      this.htmlNbody.animate({
        scrollTop: 0,
      });
    });

    this.scrollDown.on('click', () => {
      this.htmlNbody.animate({
        scrollTop: 300,
      });
    });

    this.inputs
      .on({
        focus: (e) => {
          const self = $(e.currentTarget);
          self.closest('.element').addClass('active');
        },
        blur: (e) => {
          const self = $(e.currentTarget);
          if (self.val() !== '') {
            self.closest('.element').addClass('active');
          } else {
            self.closest('.element').removeClass('active');
          }
        },
      })
      .trigger('blur');

    // Reload the current path when changing language instead of redirecting to landing page
    // Uncomment below and modify languages
    // $container.on('click', '.language-toggle', function(e) {
    //   e.preventDefault();
    //   const $this = $(this);
    //   const href = $this.attr('href');
    //   const isEnglish = href.indexOf('/ar') >= 0;
    //   const locArray = location.pathname.split('/');
    //   const indexOfIndex = locArray.indexOf('index.php');
    //   const isDev = indexOfIndex >= 0;
    //   const index = isDev ? indexOfIndex + 1 : 1;
    //   if(!isEnglish) {
    //     locArray = locArray.filter(item => item !== 'ar')
    //   }
    //   locArray.splice(index, 0, isEnglish ? 'ar' : '');
    //   const newHref = locArray.join('/').replace('//', '/');
    //   location.href = newHref;
    // });

    // Uncomment below if you need to add google captcha (also in includes/script.php)
    // => Make sure the SITEKEY is changed below
    // this.gRecaptcha.each((index, el) => {
    //   grecaptcha.render(el, {'sitekey' : '6LeB3QwUAAAAADQMo87RIMbq0ZnUbPShlwCPZDTv'});
    // });
  };

  windowResize = () => {
    this.screenWidth = this.window.width();
    this.screenHeight = this.window.height();

    // calculate footer height and assign it to wrapper and push/footer div
    if (this.pushDiv.length){
      this.footerHeight = this.footer.outerHeight();
      this.wrapper.css('margin-bottom', -this.footerHeight);
      this.pushDiv.height(this.footerHeight);
    }
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();

    /*this.header.toggleClass('scroll_start', topOffset > 200);
    this.header.toggleClass('top', topOffset > 350);
    this.header.toggleClass('sticky_header', topOffset > 600);

    this.header.toggleClass('sticky', topOffset > 350);
    if (topOffset > this.previousScroll || topOffset < 250) {
      this.header.removeClass('sticky');
    } else if (topOffset < this.previousScroll) {
      this.header.addClass('sticky');
      // Additional checking so the header will not flicker
      if (topOffset > 250) {
        this.header.addClass('sticky');
      } else {
        this.header.removeClass('sticky');
      }
    }*/
    this.header.toggleClass('top', topOffset > 100);
    this.header.toggleClass('sticky', topOffset > 350);
    if (topOffset > this.previousScroll || topOffset < 250) {
      this.header.removeClass('sticky');
    } else if (topOffset < this.previousScroll) {
      this.header.addClass('sticky');
      // Additional checking so the header will not flicker
      if (topOffset > 250) {
        this.header.addClass('sticky');
      } else {
        this.header.removeClass('sticky');
      }
    }

    this.previousScroll = topOffset;

    this.gotoTop.toggleClass(
      'active',
      this.window.scrollTop() + this.screenHeight +500  >  this.footer.position().top,
    );

    this.gotoTop.toggleClass(
      'stop_move',
      this.window.scrollTop() + this.screenHeight   >  this.footer.position().top,
    );


    if(!this.htmlBody.hasClass('edit-mode') && this.overlay_footer.length ){
      if (this.window.scrollTop() + this.screenHeight -500 >  this.history_block_outer.offset().top && this.window.scrollTop() + this.screenHeight <  this.footer.position().top ) {
        this.overlay_footer.addClass('show_div')
      } else {
         this.overlay_footer.removeClass('show_div')
      }
    }
    
  };

  handleSplashScreen() {
    this.htmlBody.find('.logo-middle').fadeIn(500);
    this.siteLoader.delay(1500).fadeOut(500);
  }

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.htmlBody.addClass('ios-device');
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.htmlBody.addClass('android-device');
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.htmlBody.addClass('win-os');
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.htmlBody.addClass('mac-os');
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf('MSIE') !== -1
      || navigator.appVersion.indexOf('Trident/') > 0
    ) {
      this.html.addClass('ie10');
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass('ieEdge');
    }

    // Specifically for IE8 (for replacing svg with png images)
    if (this.html.hasClass('ie8')) {
      const imgPath = '/themes/theedge/images/';
      $('header .logo a img,.loading-screen img').attr(
        'src',
        `${imgPath}logo.png`,
      );
    }

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass('ie9')) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>',
      );
      this.htmlBody.prepend(message);
    }
  };
}();
